const SmoothScroll = require("smooth-scroll");
const axios = require("axios");

// Smooth scroll behaviro for the menu links
new SmoothScroll('a[href*="#"]', {
  header: "[data-scroll-header]",
  easing: "easeInOutCubic",
  speed: 2000,
  topOnEmptyHash: true,
  updateURL: true
});

// Listener for applying a class to the navbar on scroll
window.addEventListener("scroll", event => {
  const menu = document.getElementById("menu");
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if (scrollPosY > 50) {
    menu.classList.add(`scrolling`);
  } else if (scrollPosY <= 100) {
    menu.classList.remove(`scrolling`);
  }
});

// Custom JS

const request = document.getElementsByClassName("request");
for (let i = 0; i < request.length; i++) {
  request[i].addEventListener("click", () => {
    swal("Enter your email to request an invite", {
      content: "input",
      buttons: {
        confirm: "Request invite"
      }
    }).then(value => {
      if (!value) {
        swal.close();
      }
      if (value) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          let data = {
            fields: {
              email: value
            }
          };
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json"
            }
          };
          axios
            .post(
              "https://api.airtable.com/v0/appfB7qRv4StLTure/Table%201?api_key=keyer3EZ6bTZr7nr3",
              data,
              axiosConfig
            )
            .then(response => {
              swal("Thank you! We will be in touch shortly.", {
                icon: "success"
              });
            })
            .catch(error => {
              swal("Connection error", {
                icon: "warning"
              });
            });
        } else {
          swal("Please, enter a valid address", {
            icon: "warning"
          });
        }
      }
    });
  });
}
